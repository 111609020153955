/*Color Overrides*/
$pt-intent-primary: #5f537d;

/*Blueprint Init*/
@import "~normalize.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/src/blueprint.scss";

//#root {
//	height: 100vh !important;
//}

//froalaEditor.js

.froala-parent-container > div {
	width: 100%;
	height: calc(100vh - 114px) !important;
}

.froala-parent-container #logo {
	display: none !important;
}

//.show-placeholder > div:first-child {
//	display: none !important;
//}

.public-DraftEditor-content {
	min-height: 48em;
	padding: 80px!important;
}

.Draftail-Editor--readonly .DraftEditor-editorContainer {
	opacity: 1 !important;
}

div:focus, button:focus, a:focus {
	outline: 0;
}

.fr-element {
	padding: 96px !important;
}
a:hover {
	text-decoration: none;
}

.auth-parent {
	display:flex; 
	justify-content: center; 
	align-items:center;
	height: 100vh;
}

.auth-container {
	display: flex; flex-direction: column; padding:20px;
}

.auth-container > * {
	margin: 10px auto;
	width: 100%;
}

//linkbutton.js

.notification-dot {
	color: #006100 !important;
}

// login.js

.auth-page {
	display: flex;
	width: 100vw;
	height: 100vh;
}

.auth-page > div{
	width: 50%;
	margin: auto;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 50px 6vw;
	position: relative;
}

.auth-page > div:first-child > div{
	height: 80vh;
	flex-direction: column;
	justify-content: space-between;
}

.auth-page-illustration {
	height: 60vh;
	position: absolute;
	right: 0px;
	bottom: 5vh;
	z-index: -1;
}

.auth-page ul {
	list-style-image: url(images/icons/list-bullet.png);
	line-height: 30px;
}

.PhoneInputInput {
	outline: none !important;
	border: none;
	border-radius: 3px;
	box-shadow: 0 0 0 0 rgba(95, 83, 125, 0), 0 0 0 0 rgba(95, 83, 125, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
	background: #ffffff;
	height: 40px !important;
	padding: 0 10px;
	vertical-align: middle;
	line-height: 40px;
	color: #182026;
	font-size: 16px;
	font-weight: 400;
	transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

//regisert.js

.register-card {
	min-width: 350px;
}

//organisation.js the main dashboard

.mobile-header {
	display: none;
}

.main-dashboard-container {
	display: grid;
	grid-template-columns: 250px 1fr;
}

//.dashboard-sidebar > div:last-of-type {
//	height: 100vh;
//	overflow-y: auto;
//}

//documents.js main document holder on dashboard

.header {
	background-color: rgba(0, 0, 0, 0.02);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 10px 20px;
}


.search-area input{
	padding-right: 15px !important;
}

.editor-page-grid {
	display: grid;
	grid-template-columns: 7fr 3fr;
}

.editor-page-grid > div:first-of-type > div:first-of-type {
	position: relative !important;
	box-shadow: none;
}

.editor-page-grid > div:last-of-type > div:first-of-type {
	border-bottom: 1px solid lightgrey;
	height: 50px;
	padding: 7px 0px;
}

.editable-document-name {
	max-width: 250px;
	overflow: hidden;
}

.editor-page-menu-button {
	display: none;
}

//credits.js

.credits-body-container {
	display: grid;
	grid-template-columns: 20% 60% 20%;
	margin-top: 50px;
	height: calc(100vh - 50px);
}

.credits-body-container > div {
	padding: 15px;
	height: 100%;
	overflow-y: auto;
}

.credits-body-container > div:first-child, .credits-body-container > div:last-child {
	background-color: rgb(243, 246, 249);
}

.hover-grey-bg {
	background-color: rgba(167, 182, 194, 0.3);
}

//converstation.js

.message-input {
	max-height: 60px !important;
	overflow-y: auto;
}

//documentList.js

.doc-preview-text::first-letter {
	font-size: 3rem !important;
	line-height: 3rem;
	text-transform: uppercase;
	padding-right: 15px !important;
}

.doc-preview-text {
	position: absolute;
}

.doc-preview-line {
	word-break: break-all;
	font-size: 10px;
	line-height: 10px !important;
}

@media only screen and (max-width: 1024px) {
	//credits.js

	.credits-body-container {
		grid-template-columns: 25% 50% 25%;
	}
}

@media only screen and (max-width: 600px) {

	//login.js

	.auth-page {
		flex-direction: column-reverse;
		height: auto;
	}

	.auth-page > div {
		height: auto;
		width: 100%;
		padding: 50px 20px 20px 20px !important;
	}

	// froala

	.froala-parent-container .fr-element {
		padding: 20px !important;
	}

	//documents.js main document holder on dashboard



	.header {
		height: auto;
	}

	.editor-page-menu-button {
		display: block;
		float: right;
	}

	.editor-page-grid {
		grid-template-columns: 1fr 0fr;
	}

	.editor-page-grid > div:last-of-type > div:first-of-type {
		padding: 0;
	}

	.editor-page-grid > div:last-of-type > div:first-of-type div {
		width: 100%;
	}

	.saving-spinner-contianer {
		width: auto !important;
	}

	.saving-spinner-contianer div {
		width: auto !important;
	}

	//organisation.js the main dashboard

	.mobile-header {
		height: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0px 20px;
	}

	.mobile-header img {
		height: 35px;
	}

	.main-dashboard-container {
		grid-template-columns: auto;
	}

	.left-sidebar {
		position: absolute;
		width: 70%;
		z-index: 100;
		left: -70%;
		transition: 0.6s ease-in;
	}

	//credits.js

	.credits-body-container {
		grid-template-columns: 100%;
		height: auto;
	}
}
