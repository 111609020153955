@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai&family=Mulish&family=Open+Sans&family=Quicksand&family=Roboto&family=Zilla+Slab&display=swap');

body {
    width: 100vw;
    overflow: hidden;
}

.body-container-height {
    height: calc(100vh - 50px);
}

.z-index-10 {
    z-index: 10 !important;
}

.z-index-20 {
    z-index: 20 !important;
}

.PhoneInput input::placeholder {
    color: #9ea9b3 !important;
}

@media only screen and (max-width: 600px) {
    .role-card {
        max-width: auto;
    }
    .dashboard-main {
        overflow-y: auto;
    }
}

*::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #fff;
}

*::-webkit-scrollbar
{
    height: 5px !important;
	width: 5px;
	background-color: #fff;
}

*::-webkit-scrollbar-thumb
{   
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 0px rgba(0,0,0,.3);
	background-color: rgb(112, 112, 112);
}


.bp3-editable-text-input:focus, .bp3-input.bp3-active{
    box-shadow: 0 0 0 1px #5f537d, 0 0 0 3px rgba(95, 83, 125, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2) !important;
}

.bp3-editable-text-input:focus ~ .bp3-editable-text-placeholder {
    display: none !important;
}

.bp3-editable-text-input { 
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.bp3-editable-text-content {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.pdfContainer {
    position: relative;
    /* width: max-content; */
    /* max-width: 594px; */
    max-width: 100%;
    /* max-height: calc(90vh - 40px); */
    /* overflow: auto; */
    height: 100%;
}

.pdfContainer .pdfImage{
    border: 1px solid #999;
    /* width: 100%; */
}

.canvas-container {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.pdfUpperCanvas, .upper-canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.fr-wrapper {
    position: relative;
}

.pageBreakLayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}

.pageBreakLine {
    margin-top: 1120px;
    border-bottom: 1px dashed #5f537d;
    display: flex;
    align-items: center;
    justify-content: center;
}

#editor-sidebar {
    width: 30%;
    max-width: 415px;
    z-index: 3;
}

#sign-now-btn {
    display: none;
}

#pdf-container {
    
}

@media only screen and (max-width: 640px) {
    #editor-sidebar {
        overflow: hidden;
        position: absolute;
        top: 0;
        right: -100%;
        transition: 0.2s;
        width: 0;
        max-width: 100vw;
        background: #fff;
    }

    #editor-sidebar.open {
        right: 0;
        width: 100vw;
    }

    #sign-now-btn {
        display: block;
    }
}