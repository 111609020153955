@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&display=swap');

:root {
    --primary-color: #403f42;
    --primary-bg-color: #F6F6F6;
    --primary-text-color: #2F2E41;
    --secondary-text-color: #707070;
    --white-background: #fff;
    --light-grey-background: #f6f6f6;
    --white-color: #fff;
}

p {
    font-size: 1rem;
    line-height: 1.5rem;
}

html, body {
    color: var(--secondary-text-color);
    font-size: 1rem;
    height: 100%;
    background: #f3f3f3;
    overflow: auto;
}

div#root {
    height: 100%;
}

.primary-bg {
    background: var(--primary-bg-color) !important;
    background-color: var(--primary-bg-color) !important;
}

.bingo-bg.bp3-dialog{
    background: var(--primary-bg-color) !important;
    background-color: var(--primary-bg-color) !important;
}

.purple-bg {
    background-color: var(--primary-color) !important;
    background-color: var(--primary-color) !important;
}

.uk-h1, .uk-h2, .uk-h3, .uk-h4, .uk-h5, .uk-h6, .uk-heading-2xlarge, .uk-heading-large, .uk-heading-medium, .uk-heading-small, .uk-heading-xlarge, h1, h2, h3, h4, h5, h6, body {
    font-family: Roboto !important;
}

.bp3-icon.bp3-intent-primary, .bp3-icon-standard.bp3-intent-primary, .bp3-icon-large.bp3-intent-primary {
    color: var(--primary-color) !important;
}

.uk-tab>.uk-active>a {
    border-color: var(--primary-color) !important;
}

.uk-text-lead {
    font-size: 1rem !important;
    color: #2b2b2b !important;
}

.uk-text-meta {
    font: 0.75rem !important;
    color: #707070 !important;
}

.uk-text-large, .uk-text-lead {
    color: var(--primary-text-color) !important;
}

.uk-text-large {
    font-size: 1.25rem !important;
}

.uk-input, .uk-select:not([multiple]):not([size]) {
    height: 45px;
}

.uk-input, .uk-select, .uk-textarea {
    background: #F8F8F8;
    border: 1px solid #F8F8F8;
}

.uk-button-primary {
    background-color: var(--primary-color) !important;
    color: #fff !important;
    border-radius: 5px;
}

.uk-button-primary:focus, .uk-button-primary:hover {
    background-color: #ECECEC !important;
    color: var(--primary-color) !important;
}

.uk-button-secondary {
    background-color: #ECECEC !important;
    color: var(--primary-color) !important;
    border-radius: 5px !important;
}

.uk-button-secondary:hover, .uk-button-secondary:focus {
    background-color: var(--primary-color) !important;
    color: #fff !important;
}

.uk-button-default {
    border-radius: 5px !important;
}

.uk-button-link {
    color: var(--primary-color);
}

.uk-button-link:hover {
    color: var(--primary-text-color);
}

.uk-color-primary {
    color: var(--primary-color) !important;
}

.stepper-list > .uk-active svg *{
    fill: var(--primary-color) !important;
}

.notification-tray .uk-subnav-pill>.uk-active>a {
    background-color: var(--primary-color);
    color: var(--white-color) !important;
}

.notification-tray .uk-subnav-pill>li {
    text-align: center;
}

.notification-tray .uk-subnav-pill>li>a {
    color: var(--primary-text-color);
    width: 100% !important;
    padding: 0.75rem;
    color: var(--primary-text-color) !important;
}

.uk-subnav-pill>.uk-active>a {
    background-color: var(--primary-color);
}

.uk-checkbox:checked, .uk-checkbox:indeterminate, .uk-radio:checked {
    background-color: var(--primary-color) !important;
}

.uk-navbar-item, .uk-navbar-nav>li>a, .uk-navbar-toggle {
    min-height: 60px;
}


.fancy-heading {
    padding-left: 20px;
    border-left: 5px solid var(--primary-color);
}

.vs-icon-button-new {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    border: 0px;
    font-size: 1rem;
    color: var(--secondary-text-color);
    cursor: pointer;
}

.vs-icon-button-new .button-icon, .vs-icon-button-new .button-text, .vs-icon-button-new .button-count {
    padding: 10px;
}

.vs-icon-button-new .button-icon > img {
    width: 18px !important;
}

.vs-icon-button-new:hover, .vs-icon-button-new:disabled {
    background-color: var(--primary-bg-color);
    color: var(--primary-text-color) !important;
}

.vs-icon-button-new:hover .button-text, .vs-icon-button-new:hover .button-count, .vs-icon-button-new:disabled .button-text, .vs-icon-button-new:disabled .button-count {
    font-weight: bold;
}

.settings-modal .uk-tab>*>a {
    padding: 0;
}

.vs-green-button {
    background-color: #2ad9b0;
    color: #fff;
    border-radius: 5px;
}

.vs-green-button:hover, .vs-green-button:active {
    background-color: #fff;
    color: #2ad9b0;
}

.editor-page-contextual-nav > div {
    position: relative !important;
}

/* org switcher button customisation  */


.organisation-image {
    padding: 0px !important;
}

.vs-icon-button:hover > .organisation-image > img, .vs-icon-button:focus > .organisation-image > img, .vs-icon-button.active > .organisation-image > img, .vs-icon-button:disabled > .organisation-image > img {
    -webkit-filter: none !important;
    filter: none !important;
}

/* dashboard document grid  */

.dashboard-main {
    height: 100%;
    min-height: calc(100vh - 50px);
    /* overflow-y: auto; */
    background-color: var(--primary-bg-color);
}

.document-card-container {
    max-width: 169px;
    margin: 10px;
}

.document-card {
    width: 175px;
    height: 225px;
    position: relative;
    background-color: var(--white-background);
}

.document-card > {
    background-color: var(--white-background) !important;
}

.document-card::before {
    content: '';
    position: relative;
    top: 0;
    right: 0;
    float: right;
    width: 0;
    height: 0;
    border: 30px solid var(--primary-bg-color);
    border-bottom-color: var(--white-color) ;
    border-left-color: var(--white-color);
}

.document-letter {
    font-size: 2.8rem;
    font-weight: 600;
    color: #e5e5e5;
    text-transform: capitalize;
}

/* notificatio  */

.notification-tray {
    color: var(--primary-text-color);
}

.notification-item li:hover {
    background-color: whitesmoke;
    cursor: pointer;
}

.tab-button {
    border-radius: 0px !important;
}

.tab-button.bp3-active {
    background-color: var(--primary-color) !important;
    color: #fff !important;
}

.tab-button:hover svg, .tab-button.bp3-active svg {
    fill: #fff !important;
}


/* document preview  */

.preview-output-dialog {
    width: 80vw;
    height: 90vh;
}

/* document editor */

.second-toolbar, .fr-toolbar, .fr-wrapper {
    border: none !important;
    border-radius: 0 !important;
}

.fr-box {
    box-shadow: none !important;
}

.second-toolbar {
    border-top: 1px solid #efefef !important;
    box-shadow: 0 2px 8px rgba(0,0,0,.08) !important;
}


/* blueprint customisations */

.bp3-dialog {
    background: #fff !important;
    border-radius: 0px !important;
}

.bp3-dialog-header {
    min-height: 50px;
    box-shadow: 0 2px 8px rgba(0,0,0,.08) !important;
}

.bp3-dialog-header, .bp3-heading {
    font-size: 1.25rem !important;
    line-height: 1.5 !important;
    color: var(--secondary-text-color) !important;;
}

.bp3-button.bp3-minimal:hover {
    background: #f6f6f6;
}

.bp3-navbar {
    box-shadow: 0 2px 8px rgba(0,0,0,.08) !important;
}

.uk-badge {
    font-size: 0.75rem;
    padding: 3px 10px !important;
}

.bp3-alert-footer button {
    box-shadow: none !important;
    background-image: none !important;
    background-color: var(--primary-color) !important;
    color: #fff !important;

}

.editor-page-contextual-nav .bp3-align-left, .editor-page-contextual-nav .bp3-navbar-heading {
    width: 100% !important;
}

/* .from-template-card:hover .bp3-icon > svg {
    fill: pink !important;

} */

@media only screen and (max-width: 640px) {
    .public-doc-callout {
        position: absolute;
        height: 0;
        width: 0;
        overflow: hidden;
    }
    #public-doc-signature-btn {
        position: fixed;
        bottom: 20px;
        left: 20px;
    }
}