.deploy-card {
    
    padding: 5px;
    max-width: 95%;
    
}

.deploy-card:hover {
    background-color: aliceblue;
    cursor: pointer;
}

.deploy-card-head {
    margin-bottom: 5px;
}

.deploy-card-body {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
